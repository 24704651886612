<template>
  <b-modal
    :ref="modalRef"
    :id="modalId"
    size="xl"
    :title="title"
    @show="onShow"
    @hide="onHide"
    centered
    class="m-0 p-0"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay :show="isLoading">
      <div>Felder zum Bearbeiten auswählen:</div>
      <div class="d-flex align-items-center mt-2" style="gap: 8px">
        <Multiselect
          v-model="fieldsToEdit"
          :options="orderedFieldEditOptions"
          selectLabel=""
          selectGroupLabel=""
          deselectLabel=""
          deselectGroupLabel=""
          :close-on-select="false"
          :searchable="false"
          :multiple="true"
          label="label"
          track-by="key"
          selectedLabel="Ausgewählt"
          :placeholder="null"
          class="w-75"
          size="md"
        ></Multiselect>
        <i
          v-if="fieldsToEdit.length > 0"
          class="fas fa-times text-danger align-self-center"
          @click="fieldsToEdit = []"
          style="cursor: pointer"
        ></i>
      </div>
      <div class="mt-4 grid">
        <div v-for="item in fieldsToEdit" :key="item.label">
          <b-form-group :label="item.label">
            <b-form-input
              v-if="item.type === 'number'"
              size="sm"
              v-model="item.value"
              :number="true"
              type="number"
              min="0"
              max="365"
              step="1"
              required
            ></b-form-input>

            <b-form-input
              v-if="item.type === 'input'"
              size="sm"
              v-model="item.value"
              class="p-2 leftLabel"
              placeholder="-"
            ></b-form-input>

            <b-form-select
              v-if="item.type === 'select'"
              size="sm"
              v-model="item.value"
              :options="item.selectOptions"
            ></b-form-select>

            <AirportsSucheMultiselect
              v-if="item.type === 'flughafen'"
              v-model="item.value"
              :isMultipleAllowed="false"
              :openDirection="'top'"
              id="abflughafen"
              :filterCountry="country"
            ></AirportsSucheMultiselect>
          </b-form-group>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        @click="handleSubmit(hide)"
        :disabled="fieldsToEdit.length === 0"
        :variant="fieldsToEdit.length > 0 ? 'primary' : ''"
      >
        Speichern
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import AirportsSucheMultiselect from '@/components/common/airports-suche-multiselect.vue';
import Multiselect from 'vue-multiselect';
import apiService from '@/core/common/services/api.service';

export default {
  props: {
    title: { type: String, default: 'Reisetermine bearbeiten' },
    isLoading: { type: Boolean, default: false },
    country: { type: String, default: null },
    editOptions: {
      type: Array,
      required: true,
    },
    modalRef: {
      type: String,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  components: {
    Multiselect,
    AirportsSucheMultiselect,
  },
  computed: {
    orderedFieldEditOptions() {
      return this.editOptions.slice().sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  data() {
    return {
      fieldsToEdit: [],
      resultsAirlines: [],
    };
  },
  watch: {
    fieldsToEdit: {
      handler(fields, oldFields) {
        const fieldKeys = fields.map(field => field.key);
        const oldFieldKeys = oldFields.map(field => field.key);

        if (!oldFieldKeys.includes('flugnummerGruppeHin') && fieldKeys.includes('flugnummerGruppeHin')) {
          const autoFields = ['optionsfrist', 'ticketfrist'];

          autoFields.forEach(fieldKey => {
            if (!fieldKeys.includes(fieldKey)) {
              const fieldToAdd = this.editOptions.find(option => option.key === fieldKey);
              if (fieldToAdd) {
                this.fieldsToEdit.push(fieldToAdd);
              }
            }
          });
        }

        const flugnummerGruppeHinField = this.fieldsToEdit.find(field => field.key === 'flugnummerGruppeHin');

        const flugnummerHin = flugnummerGruppeHinField ? flugnummerGruppeHinField.value : null;

        const checkFlugNumber = async flugNr => {
          if (flugNr && flugNr?.length === 2) {
            const airlineKuerzel = flugNr.substring(0, 2);
            if (!this.resultsAirlines.some(result => result.airlineKuerzel === airlineKuerzel)) {
              try {
                const res = await apiService.get(`airline?$filter=airlineKuerzel eq '${airlineKuerzel}'`);
                const { result } = res.data;
                if (result.length === 1) {
                  this.resultsAirlines.push(result[0]);
                  this.setFristen(result[0].optionsfrist, result[0].ticketingfrist);
                } else {
                  this.resultsAirlines.push({ airlineKuerzel });
                }
              } catch (error) {
                console.error('Fehler beim Abrufen der Airline-Daten:', error);
              }
            }
          }
        };
        checkFlugNumber(flugnummerHin);
      },
      deep: true,
    },
  },
  methods: {
    onShow() {
      // es wird überprüft, ob es deafault Fields gibt
      this.fieldsToEdit = this.editOptions.filter(option => option.isDefault);
    },
    onHide() {
      this.$emit('hide');
    },
    handleSubmit() {
      this.$emit('handleSubmit', this.fieldsToEdit);
    },
    async checkAndSetFristen() {},
    setFristen(optionsfrist, ticketingfrist) {
      const optionsfristField = this.fieldsToEdit.find(field => field.key === 'optionsfrist');
      const ticketingfristField = this.fieldsToEdit.find(field => field.key === 'ticketfrist');

      if (optionsfristField) {
        this.$set(optionsfristField, 'value', optionsfrist);
      }
      if (ticketingfristField) {
        this.$set(ticketingfristField, 'value', ticketingfrist);
      }
    },
  },
};
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
</style>
